// React Components
import React from 'react';
import { BrowserRouter, Routes, Route, Link, Redirect } from 'react-router-dom'

// CSS Styles
import './App.css';

// Components
import Landing from './pages/Landing/Landing'
import Footer from './pages/Footer/Footer'
import RainlabLogo from './pages/Logo/RainlabLogo';


function App() {
  return (
    <main className="App">
          <BrowserRouter>
            <RainlabLogo />
            <Landing />
            <Footer />
          </BrowserRouter>
    </main>
  );
}

export default App
