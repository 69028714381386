// React Components
import React from "react";
import { Link } from "react-router-dom";

// Style
import FooterStyles from './Footer.css'

// Images
import Email from '../../assets/mail.png'
import Facebook from '../../assets/SM_Icon_Facebook.png'
import Tiktok from '../../assets/SM_Icon_Tiktok.png'
import Twitter from '../../assets/SM_Icon_Twitter.png'
import Instagram from '../../assets/SM_Icon_Instagram.png'
// import arrowSVG from '../../../assets/curvedArrowLarge.svg'

export default function Footer() {
    
    // MailtoUI
    // const Vue = require('path/to/your/node_modules/vue/dist/vue.js');
    // const MailtoUI = require('path/to/your/node_modules/mailtoui/dist/mailtoui-min.js');
    
    // var app = new Vue({
    //     el: '#app',
    
    //     data: {
    //         email: 'supergirl@example.com',
    //     },
    
    //     computed: {
    //         mailtoHref: function() {
    //             return 'mailto:' + this.email;
    //         }
    //     },
    
    //     mounted() {
    //         MailtoUI.run(); // <--- Run MailtoUI manually
    //     },
    // });

    return (
        <footer className="footer-frame">
            <ul className="social-group">
                <li>
                    <a class="mailtoui" href="mailto:hello@rainlab.com.au?subject=Hello There!&body=Hello Rainlab Studio,">
                        <img 
                            src={Email} 
                            className="email" 
                            title="hello@rainlab.com.au"
                        />
                    </a>
                </li>
                <li>
                    <Link to="https://www.facebook.com/profile.php?id=100089900820065">
                        <img src={Facebook} className="facebook" alt="" />
                    </Link>
                </li>
                <li>
                    <Link to="https://www.tiktok.com/@rainlab_studio">
                        <img src={Tiktok} className="tiktok" alt="" />
                    </Link>
                </li>
                <li>
                    <Link to="https://twitter.com/rainlab_studio">
                        <img src={Twitter} className="twitter" alt="" />
                    </Link>
                </li>
                <li>
                    <Link to="https://www.instagram.com/rainlab_studio/">
                        <img src={Instagram} className="instagram" alt="" />
                    </Link>
                </li>
            </ul>
            <div className="contact-group">
                <div className="contact-vector" />
                <div className="contact-text">
                    <p className="">
                        Contact Me Here
                    </p>
                </div>
            </div>
        </footer>
    );
}