// React Components
import React from 'react'

// Styles
import LogoStyles from './RainlabLogo.css'

// Assets
import Logo from '../../assets/Logo_V1.png'


export default function RainlabLogo() {
    return (
        <>
            <img className="rainlab-logo" src={Logo}/>
        </>
    )
}
