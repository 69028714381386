// React Components
import React from 'react'

// Styles
import landingStyles from './Landing.css'

// Images
import landingImg from '../../assets/4634837_2464452.jpg'

export default function Landing() {
    return (
        <div className="landing-group">
                <img className="landing-img" src={landingImg} />
                <div className="white-rect"></div>
                <div className="blue-rect"></div>
            {/* <Link to="https://www.freepik.com">designed by Freepik</Link> */}
        </div>
        
    )
}